<template>
  <div class="page--list">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
          <v-data-table
            :headers="grid.headers"
            :items="grid.data"
            :loading="grid.loading"
            locale="pt"
            class="elevation-1"
          >
            <template #item.picture="{ item }">
              <v-avatar size="30" :title="item.name">
                <v-img :src="item.picture">
                  <template #placeholder>
                    <v-progress-circular
                      indeterminate
                      color="grey"
                    ></v-progress-circular>
                  </template>
                </v-img>
              </v-avatar>
            </template>

            <template #item.status="{ item }">
              <v-chip
                :color="getColor(item.status)"
                dark
                small
                v-text="item.status"
              ></v-chip>
            </template>

            <template #item.actions="{ item }">
              <v-icon
                v-if="$can('edit', 'professor') || $can('edit', 'student')"
                small
                class="mr-2"
                @click="action(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                v-if="
                  $can('delete', 'professor') ||
                  $can('delete', 'student') ||
                  $can('edit', 'student')
                "
                small
                @click="action(item)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>

          <v-navigation-drawer
            v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
            v-model="mobileFilter"
            :permanent="$vuetify.breakpoint.lgAndUp"
            :absolute="$vuetify.breakpoint.lgAndUp"
            :fixed="$vuetify.breakpoint.mdAndDown"
            right
            class="elevation-1"
          >
            <v-card elevation="0" class="pa-4">
              <v-card-title class="grey--text text--darken-2"
                >Filtros</v-card-title
              >
              <v-text-field
                v-model="grid.filters.nameOrEmail"
                label="Nome ou E-mail"
                dense
                solo
              ></v-text-field>
              <v-select
                v-model="grid.filters.status"
                :items="statusDistinct"
                clearable
                multiple
                label="Situação"
                dense
                solo
              ></v-select>
              <v-text-field
                v-model="grid.filters.address"
                label="Endereço"
                dense
                solo
              ></v-text-field>
              <v-text-field
                v-model="grid.filters.phone"
                label="Telefone"
                dense
                solo
              ></v-text-field>

              <v-card-actions class="d-flex justify-end">
                <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
              </v-card-actions>
            </v-card>
          </v-navigation-drawer>
        </v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.mdAndDown" class="mt-13">
        <v-col>
          <v-btn
            color="light-blue darken-3"
            fixed
            dark
            bottom
            right
            fab
            @click="toggleMobileFilterVisibility()"
          >
            <v-badge color="red" dot :value="grid.filtered">
              <v-icon>mdi-filter</v-icon>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";

export default {
  name: "List",
  mixins: [pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/",
      },
      {
        text: "Exemplo de Listagem",
        to: "/exemplos/listar",
      }
    ],
    isMobileFilterVisible: false,
    mobileFilter: null,
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nameOrEmail: undefined,
        status: [],
        address: undefined,
        phone: undefined,
      },
      headers: [
        { text: "#", value: "id", align: "start", sortable: false },
        { text: "Foto", value: "picture", sortable: false },
        { text: "Nome", value: "name" },
        {
          text: "Email",
          value: "email",
          class: "col-email",
          cellClass: "col-email",
        },
        { text: "Situação", value: "status" },
        {
          text: "Endereço",
          value: "address",
          class: "col-address",
          cellClass: "col-address",
        },
        { text: "Telefone", value: "phone" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [
        {
          id: 1,
          picture: "https://randomuser.me/api/portraits/men/20.jpg",
          name: "Troy Bennett",
          email: "troy.bennett@example.com",
          address: "5134 Valley View Ln",
          phone: "(169)-634-7118",
          status: "active",
        },
        {
          id: 2,
          picture: "https://randomuser.me/api/portraits/men/37.jpg",
          name: "Corey Prescott",
          email: "corey.prescott@example.com",
          address: "7891 Timber Wolf Trail",
          phone: "(173)-809-0023",
          status: "active",
        },
        {
          id: 3,
          picture: "https://randomuser.me/api/portraits/women/48.jpg",
          name: "Priscilla Caldwell",
          email: "priscilla.caldwell@example.com",
          address: "6891 Miller Ave",
          phone: "(476)-428-3393",
          status: "inactive",
        },
        {
          id: 4,
          picture: "https://randomuser.me/api/portraits/men/85.jpg",
          name: "Jorge Bennett",
          email: "jorge.bennett@example.com",
          address: "6467 Avondale Ave",
          phone: "(880)-470-6705",
          status: "active",
        },
        {
          id: 5,
          picture: "https://randomuser.me/api/portraits/men/42.jpg",
          name: "David Silva",
          email: "david.silva@example.com",
          address: "5033 E North St",
          phone: "(982)-631-3810",
          status: "blocked",
        },
        {
          id: 6,
          picture: "https://randomuser.me/api/portraits/women/4.jpg",
          name: "Loretta Davidson",
          email: "loretta.davidson@example.com",
          address: "4514 Brown Terrace",
          phone: "(304)-526-7444",
          status: "active",
        },
        {
          id: 7,
          picture: "https://randomuser.me/api/portraits/women/15.jpg",
          name: "Annie Morgan",
          email: "annie.morgan@example.com",
          address: "3635 Lovers Ln",
          phone: "(871)-719-3077",
          status: "inactive",
        },
        {
          id: 8,
          picture: "https://randomuser.me/api/portraits/men/51.jpg",
          name: "Felix Day",
          email: "felix.day@example.com",
          address: "922 White Oak Dr",
          phone: "(784)-860-3180",
          status: "active",
        },
        {
          id: 9,
          picture: "https://randomuser.me/api/portraits/women/43.jpg",
          name: "Jessie Armstrong",
          email: "jessie.armstrong@example.com",
          address: "2931 Brown Terrace",
          phone: "(038)-671-6830",
          status: "active",
        },
        {
          id: 10,
          picture: "https://randomuser.me/api/portraits/men/59.jpg",
          name: "Miguel Evans",
          email: "miguel.evans@example.com",
          address: "3663 Hamilton Ave",
          phone: "(905)-842-5272",
          status: "active",
        },
        {
          id: 11,
          picture: "https://randomuser.me/api/portraits/women/93.jpg",
          name: "Riley Peck",
          email: "riley.peck@example.com",
          address: "5505 Avondale Ave",
          phone: "(055)-498-8037",
          status: "active",
        },
        {
          id: 12,
          picture: "https://randomuser.me/api/portraits/men/15.jpg",
          name: "Miguel Ferguson",
          email: "miguel.ferguson@example.com",
          address: "6433 Oak Lawn Ave",
          phone: "(312)-303-8971",
          status: "active",
        },
        {
          id: 13,
          picture: "https://randomuser.me/api/portraits/women/2.jpg",
          name: "Christina Martin",
          email: "christina.martin@example.com",
          address: "7118 Oak Ridge Ln",
          phone: "(229)-875-6444",
          status: "active",
        },
        {
          id: 14,
          picture: "https://randomuser.me/api/portraits/women/42.jpg",
          name: "Sheila Palmer",
          email: "sheila.palmer@example.com",
          address: "6321 Northaven Rd",
          phone: "(066)-802-3830",
          status: "active",
        },
        {
          id: 15,
          picture: "https://randomuser.me/api/portraits/men/91.jpg",
          name: "Robert Curtis",
          email: "robert.curtis@example.com",
          address: "9934 Karen Dr",
          phone: "(240)-086-1968",
          status: "active",
        },
      ],
    },
  }),
  computed: {
    statusDistinct() {
      function distinct(value, index, self) {
        return self.indexOf(value) === index;
      }
      return this.grid.data.length
        ? this.grid.data.map((user) => user.status).filter(distinct)
        : [];
    },
  },
  beforeMount() {
    this.hidePageLoading();
  },
  methods: {
    action(item) {
      console.log(">> Action: ", item);
    },
    getColor(status) {
      if (status === "inactive") {
        return "grey";
      }
      if (status === "blocked") {
        return "red";
      }

      return "success";
    },
    toggleMobileFilterVisibility() {
      this.mobileFilter = !this.mobileFilter;
    },
    filter() {
      console.log(">> Filtros: ", this.grid.filters);

      if (
        this.grid.filters.nameOrEmail ||
        this.grid.filters.status.length ||
        this.grid.filters.address ||
        this.grid.filters.phone
      ) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}
</style>